import {useRouteError} from '@remix-run/react';
import {captureRemixErrorBoundaryError} from '@sentry/remix';

/**
 * Root ErrorBoundary
 *
 * This is the last resort to display when something critical breaks.
 * This boundary is not expected to be triggered and mainly serves as logger for unexpected error scenarios.
 */
export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  // TODO: Add some styling independent of UI library etc. to at least display something even though it should never happen
  return <></>;
}
