import {type CSSVariablesResolver, rem, rgba} from '@mantine/core';

export const cssVariablesResolver: CSSVariablesResolver = theme => ({
  variables: {
    '--observatory-font-family-number': theme.other.fontFamilyNumber,
    '--observatory-color-score-critical': theme.other.scoreColors.critical,
    '--observatory-color-score-low': theme.other.scoreColors.low,
    '--observatory-color-score-high': theme.other.scoreColors.high,
    '--observatory-color-score-medium': theme.other.scoreColors.medium,
    '--observatory-color-text-0': theme.other.textColors[0],
    '--observatory-color-text-1': theme.other.textColors[1],
    '--observatory-color-text-2': theme.other.textColors[2],
    '--observatory-color-text-3': theme.other.textColors[3],
    '--observatory-color-text-4': theme.other.textColors[4],
    '--observatory-header-height': rem(60),
    '--observatory-sidebar-width': rem(300),
  },
  light: {
    '--mantine-color-text': theme.other.textColors[0],
    '--observatory-color-bg-0': theme.colors.gray[1],
    '--observatory-color-bg-1': theme.colors.gray[0],
    '--observatory-color-bg-2': theme.white,
    '--observatory-color-border': theme.other.textColors[4],
  },
  dark: {
    '--mantine-color-text': theme.other.textColors[4],
    '--observatory-color-bg-0': theme.colors.dark[8],
    '--observatory-color-bg-1': theme.colors.dark[6],
    '--observatory-color-bg-2': theme.colors.dark[7],
    '--observatory-color-border': rgba(theme.other.textColors[4], 0.2),
  },
});
