import "@mantine/carousel/styles.css?__remix_sideEffect__";
import "@mantine/charts/styles.css?__remix_sideEffect__";
import "@mantine/core/styles.css?__remix_sideEffect__";
import "@mantine/nprogress/styles.css?__remix_sideEffect__";
import "@mantine/spotlight/styles.css?__remix_sideEffect__";
import { cssBundleHref } from '@remix-run/css-bundle';

import { MantineProvider } from '@mantine/core';
import {
  NavigationProgress,
  completeNavigationProgress,
  resetNavigationProgress,
  startNavigationProgress } from
'@mantine/nprogress';
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useNavigation } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
import { useEffect } from 'react';

import { resolveWebHost } from '@rockawayxlabs/observatory-utils';

import { json, useLoaderData } from '~/features/serialization';
import { cssVariablesResolver, theme } from '~/features/theme';
import { getColorSchemeSession } from '~/features/theme/colorSchemeSession.server';

export const links: LinksFunction = () => {
  const cssBundle = cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [];

  return [
  ...cssBundle,
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&family=Manrope:wght@500;700&display=swap'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicon-32x32.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicon-16x16.png'
  }];

};

export async function loader({ request }: LoaderFunctionArgs) {
  const colorSchemeSession = await getColorSchemeSession(request);
  const colorScheme = colorSchemeSession.getColorScheme() ?? 'dark';

  const webHost = resolveWebHost();

  return json({
    env: {
      APP_ENV: process.env.APP_ENV ?? process.env.NODE_ENV,
      APP_VERSION: process.env.APP_VERSION,
      FEATURE_FLAGS: process.env.FEATURE_FLAGS,
      SENTRY_DSN: process.env.SENTRY_DSN,
      WEB_ENV: process.env.WEB_ENV
    },
    colorScheme,
    webHost
  });
}

function App() {
  const { env, colorScheme, webHost } = useLoaderData<typeof loader>();
  const { state } = useNavigation();
  const location = useLocation();

  useEffect(() => {
    if (state !== 'idle') {
      startNavigationProgress();
    } else {
      completeNavigationProgress();
    }

    return resetNavigationProgress;
  }, [state]);

  useEffect(() => {
    if (location.hash) {
      const el = document.querySelector(location.hash);

      el?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [location.hash]);

  return (
    <html lang="en" data-mantine-color-scheme={colorScheme}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <MantineProvider theme={theme} cssVariablesResolver={cssVariablesResolver} forceColorScheme={colorScheme}>
          <NavigationProgress />
          <Outlet />
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(env)}`
            }} />

          <script defer data-domain={webHost} data-api="/proxy/stats/event" src="/proxy/stats/script" />
        </MantineProvider>
      </body>
    </html>);

}

export { ErrorBoundary } from './root.boundaries';

export default withSentry(App);