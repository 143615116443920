import classes from './theme.module.css';

import { Alert, Anchor, Divider, Input, Paper, SegmentedControl, Switch, Tooltip, createTheme, rem } from '@mantine/core';
import cx from 'clsx';

const DEFAULT_FONT =
'Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji';
const NUMBER_FONT =
'Manrope,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji';

export const theme = createTheme({
  defaultRadius: 0,
  fontFamily: DEFAULT_FONT,
  headings: {
    fontFamily: DEFAULT_FONT
  },
  fontSizes: {
    xs: rem(12),
    sm: rem(14),
    md: rem(16),
    lg: rem(18),
    xl: rem(22)
  },
  colors: {
    dark: [
    '#eaeaeb' /* 0 */,
    '#cacbcc' /* 1 */,
    '#abacad' /* 2 */,
    '#8b8d8f' /* 3 */,
    '#6b6d70' /* 4 */,
    '#4c4e52' /* 5 */,
    '#2c2f33' /* 6 */,
    '#1f2124' /* 7 */,
    '#181a1c' /* 8 */,
    '#121314' /* 9 */],

    blue: [
    '#e5f2ff' /* 0 */,
    '#d0ebff' /* 1 */,
    '#b3d9ff' /* 2 */,
    '#80bfff' /* 3 */,
    '#4da5ff' /* 4 */,
    '#1a8cff' /* 5 */,
    '#007fff' /* 6 */,
    '#0066cc' /* 7 */,
    '#0059b3' /* 8 */,
    '#004c99' /* 9 */]

  },
  other: {
    fontFamilyNumber: NUMBER_FONT,
    textColors: ['#050A11', '#343A40', '#868E96', '#B1B8C1', '#DCE3EC'],
    scoreColors: {
      critical: '#e54545',
      low: '#fc9f59',
      medium: '#f9d673',
      high: '#2ebcab'
    },
    getScoreColor(score: number) {
      if (score <= 25) {
        return '#e54545';
      } else if (score <= 50) {
        return '#fc9f59';
      } else if (score <= 75) {
        return '#f9d673';
      }
      return '#2ebcab';
    }
  },
  components: {
    Alert: Alert.extend({
      classNames(_, props) {
        return {
          root: cx({
            [classes['bg-2'] || '']: props.variant === 'outline'
          }),
          message: cx({
            [classes['margin-top-0'] || '']: !props.title
          })
        };
      }
    }),
    Anchor: Anchor.extend({
      classNames(_, props) {
        return {
          root: cx({
            [classes['color-link'] || '']: !props.color && !props.c
          })
        };
      }
    }),
    Paper: Paper.extend({
      classNames(_, props) {
        return {
          root: cx(classes['bg-2'], classes['color-inherit'], { [classes['border'] || '']: props.withBorder })
        };
      }
    }),
    Input: Input.extend({
      classNames: {
        input: cx(classes['border-color'])
      }
    }),
    Divider: Divider.extend({
      vars() {
        return {
          root: {
            '--divider-color': 'var(--observatory-color-border)'
          }
        };
      }
    }),
    Switch: Switch.extend({
      classNames: {
        track: cx(classes['border-color'])
      }
    }),
    Tooltip: Tooltip.extend({
      classNames: {
        tooltip: cx(classes['bg-text-0'], classes['color-white'])
      }
    }),
    SegmentedControl: SegmentedControl.extend({
      classNames: {
        root: cx(classes['bg-1'], classes['border']),
        label: cx(classes['color-label'])
      }
    })
  }
});